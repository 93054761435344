import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Search, ShoppingBag, User } from "react-feather"

const Header = () => {
  return (
    <header className="full-bleed layouted bg-white">
      <div className="head-wrap flex flex-row justify-between">
        <div className="left-col flex flex-row items-center">
          <StaticImage
            src="https://cdn.shopify.com/s/files/1/0588/7823/0692/files/Group_6_260x.png?v=1628669377"
            alt="logo"
          />
          <nav>
            <a
              className="text-xl text-blue ml-4 py-4 border-blue"
              href="/"
            >
              HOME
            </a>
            <a
              className="text-xl text-blue ml-4 py-4 border-blue"
              href="/"
            >
              CONTACT
            </a>
          </nav>
        </div>
        <div className="right-col flex flex-row items-center text-blue">
          <div className="ml-4">
            <Search />
          </div>
          <div className="ml-4">
            <User />
          </div>
          <div className="ml-4">
            <ShoppingBag />
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
